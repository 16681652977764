<template>
  <div
    id="forecast-view-modal"
    ref="forecast-view-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ titleModal }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <div
            v-if="initialized"
            class="card-body modalbody p-0 w-100 d-flex justify-content-center"
          >
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationPageSize="30"
              :rowData="forecastData"
              :getRowStyle="getRowStyle"
              class="ag-theme-alpine h-100 w-100"
              sideBar="filters"
            />
          </div>
          <Spinner v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    AgGridVue,
    Spinner
  },
  data() {
    return {
      titleModal: null,
      forecastModal: null,
      initialized: false,
      forecastData: []
    };
  },
  computed: {
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      };
    },
    columnDefs() {
      return [
        {
          headerName: 'WEEK',
          field: 'week',
          width: 80,
          sortable: true
        },
        {
          headerName: 'DATE',
          field: 'date',
          width: 110,
          sortable: true
        },
        {
          headerName: 'ECOM_ORDERS',
          field: 'ecomOrders',
          width: 150,
          sortable: true
        },
        {
          headerName: 'ECOM_UNITS',
          field: 'ecomUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'ECOM_DESCRIPTION',
          field: 'ecomDescription',
          width: 150,
          sortable: true
        },
        {
          headerName: 'LTM_LASER_UNITS',
          field: 'laserUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'LTM_EMB_UNITS',
          field: 'embUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RETURNS_UNITS',
          field: 'returnsUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_UNITS',
          field: 'whUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_PO_NUMBER',
          field: 'whPoNumber',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_START_DATE',
          field: 'whStartDate',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_CANCEL_DATE',
          field: 'whCancelDate',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_PO_NUMBER',
          field: 'receivingPoNumber',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_CONTAINERS',
          field: 'receivingContainers',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_PAL_UNITS',
          field: 'receivingPalUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_CART_UNITS',
          field: 'receivingCartUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_UN_UNITS',
          field: 'receivingUnUnits',
          width: 150,
          sortable: true
        }
      ];
    }
  },
  async mounted() {
    this.forecastModal = new Modal(this.$refs['forecast-view-modal']);
  },
  methods: {
    ...mapActions({
      fetchForecastById: 'forecast/fetchForecastById',
    }),
    async initModal(forecastId) {
      this.initialized = false;
      this.titleModal = forecastId;
      this.forecastModal.show();
      this.forecastData = await this.fetchForecastById(forecastId);
      this.initialized = true;
    },
    getRowStyle(params){
      if (params.data.applied === null){
        return { background: '#ececef' };
      }
      return (params.data.applied) === false ? { background: '#fbe9eb' } : { background: '#ecfdf0' };
    }
  }
};
</script>
<style>
@import "style.css";
</style>
