<template>
  <div
    id="forecast-rule-modal"
    ref="forecastRuleModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit rules
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="rules.ecomLowerBound"
              :errors="v$.rules.ecomLowerBound.$errors"
              description="eCommerce lower bound"
              label="eCommerce lower bound"
              @blur="v$.rules.ecomLowerBound.$touch"
            />
            <TextInput
              v-model="rules.ecomUpperBound"
              :errors="v$.rules.ecomUpperBound.$errors"
              description="eCommerce upper bound"
              label="eCommerce upper bound"
              @blur="v$.rules.ecomUpperBound.$touch"
            />
            <TextInput
              v-model="rules.ltmLaserLowerBound"
              :errors="v$.rules.ltmLaserLowerBound.$errors"
              description="LTM Laser lower bound"
              label="LTM Laser lower bound"
              @blur="v$.rules.ltmLaserLowerBound.$touch"
            />
            <TextInput
              v-model="rules.ltmLaserUpperBound"
              :errors="v$.rules.ltmLaserUpperBound.$errors"
              description="LTM Laser upper bound"
              label="LTM Laser upper bound"
              @blur="v$.rules.ltmLaserUpperBound.$touch"
            />
            <TextInput
              v-model="rules.ltmEmbLowerBound"
              :errors="v$.rules.ltmEmbLowerBound.$errors"
              description="LTM Embroidery lower bound"
              label="LTM Embroidery lower bound"
              @blur="v$.rules.ltmEmbLowerBound.$touch"
            />
            <TextInput
              v-model="rules.ltmEmbUpperBound"
              :errors="v$.rules.ltmEmbUpperBound.$errors"
              description="LTM Embroidery upper bound"
              label="LTM Embroidery upper bound"
              @blur="v$.rules.ltmEmbUpperBound.$touch"
            />
            <TextInput
              v-model="rules.whWarnBefore"
              :errors="v$.rules.whWarnBefore.$errors"
              description="Wholesale warning before start"
              label="Wholesale warning before start"
              @blur="v$.rules.whWarnBefore.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="forecastEditRuleConfirmationModal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The rules of forecast will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import { mapActions } from 'vuex';
import ConfirmationModal from '../common/ConfirmationModal';
import TextInput from '../common/TextInput';
import Spinner from '../common/Spinner';
import { useVuelidate } from '@vuelidate/core';
import { Modal } from 'bootstrap';
import { decimal, integer, minValue, required }  from '@vuelidate/validators';

const defaultItem = {
  id: null,
  brandId: null,
  client: null,
  ecomLowerBound: null,
  ecomUpperBound: null,
  tmLaserLowerBound: null,
  ltmLaserUpperBound: null,
  ltmEmbLowerBound: null,
  ltmEmbUpperBound: null,
  whWarnBefore: null,
};
export default {
  components: {
    ConfirmationModal,
    TextInput,
    Spinner
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      modalViewType: null,
      confirmationModalSave: null,
      forecastEditRuleModal: null,
      initialized: false,
      rules: {
        id: null,
        brandId: null,
        client: null,
        ecomLowerBound: null,
        ecomUpperBound: null,
        ltmLaserLowerBound: null,
        ltmLaserUpperBound: null,
        ltmEmbLowerBound: null,
        ltmEmbUpperBound: null,
        whWarnBefore: null
      },
    };
  },
  validations() {
    return {
      rules: {
        ecomLowerBound: {
          required,
          decimal,
          minValue: minValue(0),
        },
        ecomUpperBound: {
          required,
          decimal,
          minValue: minValue(0),
        },
        ltmLaserLowerBound: {
          required,
          decimal,
          minValue: minValue(0),
        },
        ltmLaserUpperBound: {
          required,
          decimal,
          minValue: minValue(0),
        },
        ltmEmbLowerBound: {
          required,
          decimal,
          minValue: minValue(0),
        },
        ltmEmbUpperBound: {
          required,
          decimal,
          minValue: minValue(0),
        },
        whWarnBefore: {
          required,
          integer,
          minValue: minValue(0),
        }
      },
    };
  },
  computed: {
    isSaveButtonDisabled() {
      return !this.initialized;
    },
  },
  async mounted() {
    this.forecastEditRuleModal = new Modal(this.$refs['forecastRuleModal']);
    this.confirmationModalSave = this.$refs['forecastEditRuleConfirmationModal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchRules: 'forecast/fetchRules',
      updateRules: 'forecast/updateForecastRules',
      addRules: 'forecast/addForecastRules',
    }),
    async initModal(brandId) {
      this.initialized = false;
      this.forecastEditRuleModal.show();
      const rulesToEdit = await this.fetchRules(brandId);
      this.updateItemData(rulesToEdit);
      this.initialized = true;
    },
    async initAddModal(brandId, brand) {
      this.initialized = false;
      this.forecastEditRuleModal.show();
      const rulesToEdit = { ...defaultItem };
      rulesToEdit.brandId = brandId;
      rulesToEdit.client = brand;
      this.updateItemData(rulesToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.rules.id = newValue.id;
      this.rules.brandId = newValue.brandId;
      this.rules.client = newValue.client;
      this.rules.ecomLowerBound = newValue.ecomLowerBound;
      this.rules.ecomUpperBound = newValue.ecomUpperBound;
      this.rules.ltmLaserLowerBound = newValue.ltmLaserLowerBound;
      this.rules.ltmLaserUpperBound = newValue.ltmLaserUpperBound;
      this.rules.ltmEmbLowerBound = newValue.ltmEmbLowerBound;
      this.rules.ltmEmbUpperBound = newValue.ltmEmbUpperBound;
      this.rules.whWarnBefore = newValue.whWarnBefore;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.forecastEditRuleModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.rules
      };
      try {
        if (dataToSave.id !== null) {
          await this.updateRules(dataToSave);
        } else {
          await this.addRules(dataToSave);
        }
        this.$toast.success('Rules successfully updated');
        this.fetchRules(this.rules.brandId);
      } catch (e) {
        this.$toast.error('Failed to save changes.');
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
  }
};
</script>
