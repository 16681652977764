<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col col-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <slot name="page-header" />
          </div>
          <div class="card-body w-100">
            <div>
              <ul
                id="PageTab"
                class="nav nav-tabs"
                role="tablist"
              >
                <li
                  class="nav-item"
                  role="presentation"
                >
                  <button
                    id="general-tab"
                    aria-controls="tab-1"
                    aria-selected="true"
                    class="nav-link active"
                    data-bs-target="#tab-1"
                    data-bs-toggle="tab"
                    role="tab"
                    type="button"
                  >
                    <slot name="tab-1-header">
                      Row Editor
                    </slot>
                  </button>
                </li>
                <li
                  class="nav-item"
                  role="presentation"
                >
                  <button
                    id="profile-tab"
                    aria-controls="tab-2"
                    aria-selected="false"
                    class="nav-link"
                    data-bs-target="#tab-2"
                    data-bs-toggle="tab"
                    role="tab"
                    type="button"
                  >
                    <slot name="tab-2-header">
                      Bulk Update
                    </slot>
                  </button>
                </li>
                <li
                  class="nav-item"
                  role="presentation"
                >
                  <button
                    id="third-tab"
                    aria-controls="tab-3"
                    aria-selected="false"
                    class="nav-link"
                    data-bs-target="#tab-3"
                    data-bs-toggle="tab"
                    role="tab"
                    type="button"
                  >
                    <slot name="tab-3-header">
                      Bulk Update
                    </slot>
                  </button>
                </li>
                <li
                  class="nav-item"
                  role="presentation"
                >
                  <button
                    id="fourth-tab"
                    aria-controls="tab-4"
                    aria-selected="false"
                    class="nav-link"
                    data-bs-target="#tab-4"
                    data-bs-toggle="tab"
                    role="tab"
                    type="button"
                  >
                    <slot name="tab-4-header">
                      Bulk Update
                    </slot>
                  </button>
                </li>
              </ul>
              <div
                id="tabContent"
                class="tab-content"
              >
                <div
                  id="tab-1"
                  aria-labelledby="tab-1"
                  class="tab-pane fade show active p-0 "
                  role="tabpanel"
                >
                  <slot name="tab-1-content" />
                </div>
                <div
                  id="tab-2"
                  aria-labelledby="tab-2"
                  class="tab-pane p-0"
                  role="tabpanel"
                >
                  <slot name="tab-2-content" />
                </div>
                <div
                  id="tab-3"
                  aria-labelledby="tab-3"
                  class="tab-pane p-0"
                  role="tabpanel"
                >
                  <slot name="tab-3-content" />
                </div>
                <div
                  id="tab-4"
                  aria-labelledby="tab-3"
                  class="tab-pane p-0"
                  role="tabpanel"
                >
                  <slot name="tab-4-content" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FourTabsLayout'
};
</script>
